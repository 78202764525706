import * as React from "react"
//import { Link } from "gatsby"

//import Layout from "../components/Layout"
import Seo from "../components/SEO"

const PoliticaPrivacidadePage = () => (
  <>
    <div className="artigos-head">
      <div className="container">
        <span className="title">Política de Privacidade</span>
      </div>
    </div>
    <section className="just-text-section">
      <div className="container">
        <p className="subtitle">INFORMAÇÕES GERAIS</p>
        <p>
          A proteção dos seus dados pessoais é importante para MMV Advogados.
        </p>
        <p>
          No tratamento dos seus dados pessoais seguimos os princípios da
          licitude, lealdade, transparência, limitação das finalidades,
          minimização dos dados, exatidão, limitação da conservação, integridade
          e confidencialidade, responsabilidade, no estrito cumprimento da
          deontologia profissional.
        </p>
        <p>
          Através da presente Política de Privacidade, informamos que os seus
          dados pessoais serão tratados nos seguintes termos:
        </p>
        <br />
        <p className="subtitle">RESPONSÁVEL PELO TRATAMENTO</p>
        <p>
          A responsável pela recolha e tratamento dos seus dados pessoais é
          Maria Manuel Vieira, Advogada, com cédula profissional n.º 66565c e
          domicílio profissional sito na Rua Dr. Alberto Souto, n.º 24, 1.º
          Andar, Sala 2, 3800-148 Aveiro.
        </p>
        <br />
        <p className="subtitle">RESPONSÁVEL PELO TRATAMENTO</p>
        <p>
          Quando nos comunica os seus dados pessoais através de algum formulário
          ou qualquer outro meio de comunicação, iremos, consoante a finalidade
          aplicável, numa lista não exaustiva, proceder ao tratamento de:
        </p>
        <ul>
          <li>
            Nome, número de identificação civil e fiscal, passaporte,
            residência, profissão.
          </li>
          <li>Contacto telefónico e/ou endereço de correio eletrónico.</li>
          <li>
            Informações relativas a processos, bem como as necessárias aos
            serviços adquiridos e dados que resultem do tipo serviços
            contratados.
          </li>
        </ul>
        <br />
        <p className="subtitle">FUNDAMENTOS E FINALIDADES</p>
        <p>
          Os dados recolhidos são usados para prestação do serviço jurídico,
          disponibilização de informação solicitada pelo cliente, bem como
          orientação e gestão da relação do mandato, compreendendo, para o
          efeito, o tratamento dos dados do cliente e de terceiros.
        </p>
        <p>
          Os dados pessoais são, ainda, tratados com vista ao envio de
          comunicações comerciais relacionadas com a atividade de MMV Advogados,
          à prossecução de obrigações legais e no exercício de direitos ou na
          defesa de processos judiciais e/ou extrajudiciais.
        </p>
        <p>Constituem fundamentos à utilização:</p>
        <ul>
          <li>
            <b>Prestação de consentimento:</b> o cliente presta o seu
            consentimento de livre, esclarecida, específica e inequívoca, de
            forma escrita ou verbal.
          </li>
          <li>
            <b>Execução do contrato e de diligências pré-contratuais:</b> o
            tratamento de dados pessoais revela-se necessário para a boa
            execução do patrocínio, no âmbito da prestação de serviços em
            processo judiciais e/ou extrajudiciais.
          </li>
          <li>
            <b>Cumprimento de obrigação legal:</b> o tratamento de dados
            pessoais é necessário com vista ao cumprimento de obrigações legais,
            como a de identificação do cliente ou a da comunicação destes dados
            a entidades policiais, judiciais, fiscais ou reguladoras, bem como
            de dados de localização para assegurar eventuais serviços de
            emergência.
          </li>
          <li>
            <b>Interesse legítimo:</b> o tratamento de dados pessoais
            corresponda a um interesse legítimo de MMV Advogados, de acordo com
            a melhoria da qualidade do serviço e deteção de fraude.
          </li>
        </ul>
        <br />
        <p className="subtitle">DESTINATÁRIOS</p>
        <p>
          Os seus dados pessoais não são vendidos, alugados ou disponibilizados
          a terceiros, com finalidades comerciais, estando a partilha destes com
          terceiros, de acordo com as disposições contratuais e legais em vigor,
          estritamente vinculada a:
        </p>
        <ul>
          <li>
            Entidades públicas, no exercício do mandato, nomeadamente,
            Tribunais, Autoridade Tributária, Conservatórias, Segurança Social,
            entre outras;
          </li>
          <li>Profissionais que com MMV Advogados colaborem;</li>
          <li>
            Prestadores de serviços de MMV Advogados, sempre que pelo cliente
            tal seja solicitado, como serviços de tradução, revisão de texto,
            cópias e revisão de documentos.
          </li>
        </ul>
        <p>
          Caso a prestação de serviço implique confronto com ordenamento
          jurídico distinto do português, pode ser necessária a transferência
          dos seus dados pessoais para locais fora de Portugal. No caso de os
          dados serem transferidos para fora do Espaço Económico Europeu, e que
          não integre a lista de países que a Comissão Europeia considera
          reunirem níveis de proteção de dados pessoais adequados, são
          asseguradas tais transferências em total cumprimento das normas legais
          aplicáveis, mormente o estabelecido no capítulo V do R.G.P.D.
        </p>
        <br />
        <p className="subtitle">CONSERVAÇÃO</p>
        <p>
          Para proteção e conservação dos seus dados pessoais são implementadas
          medidas físicas de segurança adequadas, necessárias e suficientes
          contra a destruição, perda, alteração, difusão, acesso não autorizado
          ou qualquer outra forma de tratamento acidental ou ilícito.
        </p>
        <p>
          Além disso, os seus dados são tratados apenas pelo período
          estritamente necessário para a prossecução da sua finalidade e
          consoante o que for aplicável, designadamente:
        </p>
        <ul>
          <li>
            Durante toda a relação de mandato forense, ao qual acresce o prazo
            de 20 anos: para os dados tratados no âmbito da prestação dos
            serviços de advocacia.
          </li>
          <li>
            Durante 10 anos: para informação à Autoridade Tributária para
            efeitos fiscais e contabilísticos, assim como dados de escrituração
            mercantil.
          </li>
          <li>
            Durante 7 anos: como medida de combate ao branqueamento de capitais
            e ao financiamento do terrorismo.
          </li>
        </ul>
        <p>
          Após decorrido o aludido período de conservação, nos casos em que tais
          dados não devam ser conservados para finalidade distinta que possa
          subsistir, serão eliminados e destruídos.
        </p>
        <br />
        <p className="subtitle">OS SEUS DIREITOS</p>

        <p>
          O exercício dos seus direitos é gratuito, salvo tratando-se de pedido
          manifestamente infundado ou excessivo, caso em que pode ser cobrada
          uma taxa razoável atendendo os custos.
        </p>
        <p>
          Exerça os seus direitos <i>infra</i> descritos através do seguinte
          endereço eletrónico:{" "}
          <a href="mailto:geral@mmv-advogados.com">geral@mmv-advogados.com</a>.
        </p>
        <ul>
          <li>
            <b>Direito de acesso:</b> direito a obter a confirmação de quais são
            os dados pessoais tratados e informação sobre os mesmos.
          </li>
          <li>
            <b>Direito de retificação:</b> direito de solicitar a retificação
            dos dados que se encontrem incorretos, incompletos ou
            desatualizados.
          </li>
          <li>
            <b>Direito ao apagamento dos dados ou “direito a ser esquecido”:</b>{" "}
            direito a obter a eliminação dos dados pessoais, quando não se
            verifiquem fundamentos válidos para a sua conservação.
          </li>
          <li>
            <b>Direito à portabilidade:</b> direito a receber os dados que
            fornecidos, em formato digital de uso corrente e de leitura
            automática ou de solicitar a transmissão direta de tais dados para
            outrem, enquanto novo responsável pelos dados pessoais.
          </li>
          <li>
            <b>Direito a retirar o consentimento ou direito de oposição:</b>{" "}
            direito de se opor ou retirar o consentimento, a qualquer momento,
            ao tratamento de dados.
          </li>
          <li>
            <b>Direito de limitação:</b> direito de solicitar a limitação do
            tratamento dos dados pessoais, sob a forma de suspensão do mesmo a
            certas categorias ou finalidades.
          </li>
          <li>
            <b>Direito a reclamar:</b> direito a apresentar reclamação a MMV
            Advogados através do endereço eletrónico{" "}
            <a href="mailto:geral@mmv-advogados.com">geral@mmv-advogados.com</a>
            , bem como à autoridade de controlo competente - a CNPD.
          </li>
        </ul>
      </div>
    </section>
  </>
)

export const Head = () => <Seo title="Política de Privacidade" />

export default PoliticaPrivacidadePage
